@use '@angular/material' as mat;

@import './color.definition';

// Assignation des couleurs principales
$arrivage-app-primary: mat.define-palette($mat-palette-black, 900);
$arrivage-app-accent: mat.define-palette($mat-palette-yellow, 500);
$arrivage-app-warn: mat.define-palette($mat-palette-red, 900);

$button-primary: mat.define-palette($mat-palette-blue, A700);
$button-accent: mat.define-palette($mat-palette-yellow, 500);
$button-warn: mat.define-palette($mat-palette-red, 900);

$slide-toggle-primary: mat.define-palette($mat-palette-blue, A700);
$slide-toggle-accent: mat.define-palette($mat-palette-yellow, 500);
$slide-toggle-warn: mat.define-palette($mat-palette-green, 900);

// Create the theme object (a Sass map containing all of the palettes).
$arrivage-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $arrivage-app-primary,
      accent: $arrivage-app-accent,
      warn: $arrivage-app-warn,
    ),
  )
);

$button-theme: mat.define-light-theme(
  (
    color: (
      primary: $button-primary,
      accent: $button-accent,
      warn: $button-warn,
    ),
  )
);

$slide-toggle-theme: mat.define-light-theme(
  (
    color: (
      primary: $slide-toggle-primary,
      accent: $slide-toggle-accent,
      warn: $slide-toggle-warn,
    ),
  )
);

$primary: mat.get-color-from-palette($arrivage-app-primary);
$accent: mat.get-color-from-palette($arrivage-app-accent);
$warn: mat.get-color-from-palette($arrivage-app-warn);
