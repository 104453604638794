@use '@angular/material' as mat;
@import './typography.scss';
@import './theme-colors.scss';

@include mat.all-component-typographies($reactive-typography);
@include mat.core();

@include mat.all-component-themes($arrivage-app-theme);
@include mat.button-color($button-theme);
@include mat.slide-toggle-color($slide-toggle-theme);
@include mat.fab-color($button-theme);
@include mat.icon-button-color($button-theme);

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-flat-button,
.mat-mdc-raised-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  letter-spacing: 1px;
}
.mat-mdc-raised-button {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  &:not(:disabled):active {
    filter: drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14))
      drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.2));
  }
  &[disabled] {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  }
}

.mat-mdc-outlined-button,
.mat-mdc-raised-button {
  white-space: nowrap;
}

.mat-mdc-icon-button {
  min-width: fit-content;
}
