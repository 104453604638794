@use '@angular/material' as mat;
@import '../../theme/theme-colors.scss';

.ng-tooltip {
  position: fixed;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: mat.get-color-from-palette($mat-palette-black, 50);
  padding: 3px 8px;
  background: mat.get-color-from-palette($mat-palette-black, 400);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: $primary transparent transparent transparent;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent $primary transparent;
}

.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent $primary;
}

.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent $primary transparent transparent;
}

.ng-tooltip-show {
  opacity: 1;
}

.ng-tooltip {
  &-error {
    background-color: $warn;
  }
  &-accent {
    background-color: $accent;
  }
  &-success {
    background-color: mat.get-color-from-palette($mat-palette-green, 900);
  }
}
