@use '@angular/material' as mat;
@import 'node_modules/ngx-sharebuttons/themes/default.scss';
@import 'theme/theme-colors.scss';
@import 'app/styles/custom-tooltip.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&family=Source+Sans+Pro:wght@400;500&display=swap');

@media screen and (max-width: 599px) {
  html {
    font-size: 8px;
    font-family:
      Source Sans Pro,
      'Helvetica Neue',
      sans-serif;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 10px;
    font-family:
      Source Sans Pro,
      'Helvetica Neue',
      sans-serif;
  }
}

* {
  // Custom Scrollbar for Firefox since it is not compatible with webkit
  scrollbar-width: thin;
  scrollbar-color: mat.get-color-from-palette($arrivage-app-primary, 300)
    whitesmoke;
}

html {
  background-color: white;
}

body {
  max-width: 100%;
  margin: 0;
}

.big-dialog {
  max-width: unset !important;
  width: 75% !important;

  .mat-mdc-dialog-container {
    max-width: 100vw;
    min-height: 65vh;
    max-height: 100vh;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
  }
}

.full-dialog {
  max-width: unset !important;
  width: 100%;
  max-height: unset !important;

  .mat-mdc-dialog-container {
    max-width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    height: 100%;

    .mat-mdc-dialog-surface {
      border-radius: 0px !important;
    }
  }
}

.custom-dialog {
  height: fit-content;
  width: 70%;
}

.mat-mdc-dialog-container {
  border-radius: 1.6rem !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 1.6rem !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error {
  line-height: 1;
}

.mdc-text-field--filled {
  background-color: mat.get-color-from-palette(
    $mat-palette-black,
    A100
  ) !important;
}

.snackbar-success {
  &.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: mat.get-color-from-palette($mat-palette-green, 900);
  }
}

.snackbar-error {
  &.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: mat.get-color-from-palette($mat-palette-red, 900);
  }
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 2px;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#hubspot-messages-iframe-container {
  z-index: 500 !important;
}

/* Admin */
.isAdmin {
  transition-duration: 20s;
  transition-timing-function: ease-in;
  transform: rotate(360deg);
}

.admin-message {
  text-align: center;
  justify-content: center;
  font-size: 100px;
  font-style: italic;
  position: absolute;
  padding: 20px;
  top: 50%;
  left: 20%;
  z-index: 900;
  background-color: aqua;
}

.mat-mdc-select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**'
 * Some inputs fields were not aligned properly.
 *
 * The dropdown inputs with the arrow icon were a little bit higher
 * than the other inputs.
 *
 * This seems to fix the issue.
 */
.mat-mdc-select-arrow-wrapper {
  height: auto !important;
}
